import {Injectable} from "@angular/core"
import {EventDataRead} from "../eventModel"
import {Parser} from "../parser"
import {DataAccessHandlerEve2} from "../../data-access-handler.eve2.service"
import {EventsList, IListAllEvents} from "../../../interfaces/list-all-events/handler.interface"
import {getListQueryUrl, ListQuery} from "../../../interfaces/query.interface"


@Injectable()
export class ListAllEventsHandler extends DataAccessHandlerEve2 implements IListAllEvents {

  listAll(parameters?: ListQuery): Promise<EventsList> {
    const httpResponse = this.http.get<any>(
      getListQueryUrl(this.getUrl(`v2/events`), parameters))

    return this.handlingXhrResponse<any>(httpResponse)
      .then(response => {
        return {
          data: response._embedded
            .items.map((ev: EventDataRead) => Parser.dataReadEventToEvent(ev)),
          paging: Parser.toPaging(response)
        }
      })
  }

}

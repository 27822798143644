import {Injectable} from "@angular/core"
import {Parser} from "../parser"
import {DataAccessHandlerEve2} from "../../data-access-handler.eve2.service"
import {IUpdateCart, UpdateCartQuery} from "../../../interfaces/update-cart/handler.interface"
import {CartItem} from "../../../interfaces/model"


@Injectable()
export class UpdateCartHandler extends DataAccessHandlerEve2 implements IUpdateCart {

  updateQuantities(parameters: UpdateCartQuery): Promise<Array<CartItem>> {

    return this.getCurrentCartId()
      .then(cartId => {
        const httpResponse = this.http.post<any>(
          this.getUrl(`v2/carts/${cartId}/items`),
          {
            type: "ticket",
            declinationId: parameters.gauge_id,
            priceId: parameters.id,
            quantity: parameters.quantity,
          }
        )
        return this.handlingXhrResponse<any>(httpResponse)
          .then((response2: any) => {
            if (response2.code && response2.message) {
              this.showErrorMessage(response2.message)
              return Promise.reject(response2.message)
            } else {
              return Parser.toCartItems(response2)
            }
          })
      })
  }

}

<p-calendar [(ngModel)]="date" [inline]="true"
            (onMonthChange)="changeMonth($event)"
            [disabledDates]="closingDates"
            [firstDayOfWeek]="1"
            [ngClass]="{'disablePreviousMonth': disablePreviousMonth, 'disableNextMonth': !enableNextMonth}"
            (onSelect)="dateChanged($event)" [minDate]="minDate">
    <ng-template pTemplate="date" let-date>
        <span [ngClass]="{'full' : dateIsFull(date)}">{{date?.day}}</span>
    </ng-template>

</p-calendar>

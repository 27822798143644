import {Component, Inject, OnInit} from "@angular/core"
import {MenuItem, MessageService} from "primeng/api"
import {DialogService} from "primeng/dynamicdialog"
import {ILogin} from "../../../../data-access/interfaces/auth/login/handler.interface"
import {ILogout} from "../../../../data-access/interfaces/auth/logout/handler.interface"
import {AuthService} from "../../../../data-access/core/auth.service"
import {Router} from "@angular/router"
import {HelperService} from "../../../services/helper.service"


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {

  itemsConnected: MenuItem[] = this.getMenuItems(true)
  items: MenuItem[] = this.getMenuItems(false)

  constructor(private dialogService: DialogService,
              private messageService: MessageService,
              public authService: AuthService,
              private helperService: HelperService,
              private router: Router,
              @Inject("Login") private loginHandler: ILogin,
              @Inject("Logout") private logoutHandler: ILogout,
  ) {
  }

  ngOnInit(): void {
  }

  getMenuItems(connected = false): MenuItem[] {
    const menuItems = [
      {
        label: "Evénements",
        icon: "pi pi-fw pi-calendar",
        url: "/",
      },
      {
        label: "Panier",
        icon: "pi pi-fw pi-shopping-cart",
        url: "/cart"
      },
      {
        label: "Mon compte",
        icon: "pi pi-fw pi-user",
        items: [
          {
            label: connected ? "Se déconnecter" : "Se connecter",
            icon: "pi pi-fw pi-user",
            command: () => {
              if (connected) {
                this.logoutHandler.logout()
              } else {
                this.helperService.showLoginPopup()
              }
            }
          }],
      }]

    if (connected) {
      menuItems[2].items?.push(
        {
          label: "Mon compte",
          icon: "pi pi-fw pi-user",
          command: () => {
            this.router.navigateByUrl("/account")
          }
        })
    }

    return menuItems
  }
}

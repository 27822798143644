import {Component, OnInit} from "@angular/core"
import {PrimeNGConfig} from "primeng/api"
import pi18nFr from "./fr-i18n"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  constructor(private config: PrimeNGConfig) {
  }

  ngOnInit(): void {
    this.config.setTranslation(pi18nFr)
  }

  onActivate(): void {
    window.scrollTo(0, 0)
  }
}

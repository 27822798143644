import {Injectable} from "@angular/core"
import {User} from "../interfaces/model"

@Injectable({
  providedIn: "root"
})
export class AuthService {

  public isAuthenticated = false
  userInfo?: User

  constructor() {
  }

  clearUserInfo(): void {
    this.userInfo = undefined
    this.isAuthenticated = false
  }

  setUserInfo(user: User): void {
    this.userInfo = user
    this.isAuthenticated = true
  }
}

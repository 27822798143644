export default {
  closeText: "Fermer",
  prevText: "Précédent",
  nextText: "Suivant",
  clear: "Effacer",
  today: "Aujourd'hui",
  currentText: "Aujourd'hui",
  monthNames: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
  monthNamesShort: ["janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
  dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
  dayNamesShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
  dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
  weekHeader: "Sem.",
  dateFormat: "dd/mm/yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: "",
}

import {Component, Inject, OnInit} from "@angular/core"
import {FormBuilder, FormGroup, Validators} from "@angular/forms"
import {ICreateUser} from "../../../../data-access/interfaces/auth/create-user/handler.interface"
import {DynamicDialogRef} from "primeng/dynamicdialog"
import {ILogin, LoginQuery} from "../../../../data-access/interfaces/auth/login/handler.interface"
import {MessageService} from "primeng/api"
import {showFormErrors} from "../../../services/helper.service"


@Component({
  selector: "app-create-account-form",
  templateUrl: "./create-account-form.component.html",
  styleUrls: ["./create-account-form.component.scss"]
})
export class CreateAccountFormComponent implements OnInit {

  fg: FormGroup = new FormGroup({})
  fg2: FormGroup = new FormGroup({})
  toggle = false
  errors = ""
  loading = false

  constructor(private fb: FormBuilder,
              private ref: DynamicDialogRef,
              private messageService: MessageService,
              @Inject("CreateUser") private createUserHandler: ICreateUser,
              @Inject("Login") private loginHandler: ILogin,
  ) {
  }

  ngOnInit(): void {
    this.fg = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      lastName: ["", Validators.required],
      firstName: ["", Validators.required],
    })
    this.fg2 = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    })
  }

  toggleCreateLogin(): void {
    this.errors = ""
    const header = document.querySelector(".p-dialog-title")
    this.toggle = !this.toggle
    if (header) {
      header.textContent = this.toggle ? "Créer un compte" : "Se connecter"
    }
  }

  createAccount(): void {
    if (this.fg.valid) {
      this.loading = true
      this.createUserHandler.create(this.fg.value)
        .then(x => {
          this.login({
            email: this.fg.value.email,
            password: this.fg.value.password
          })
        })
    } else {
      showFormErrors(this.fg)
    }
  }

  tryLogin(): void {
    if (this.fg2.valid) {
      this.login(this.fg2.value)
    } else {
      showFormErrors(this.fg2)
    }
  }

  login(args: LoginQuery): void {
    this.errors = ""
    this.loading = true
    this.loginHandler.login(args)
      .then(() => {
          this.messageService.add({severity: "success", detail: "Vous êtes maintenant connecté", life: 3000})
          this.ref.close(true)
          this.loading = false
        },
        () => {
          this.errors = "Identifiants incorrects"
          this.loading = false
        })
  }

  cancel(): void {
    this.ref.close()
  }

  todo(): void {
    this.messageService.add({severity: "warn", detail: "TODO", life: 3000})
  }
}

import {Cart, Event, Manifestation, Price} from "../interfaces/model"

export class EmptyBuilder {
  static getEmptyEvent(): Event {
    return {
      title: "",
      category: "",
      imageUrl: "",
      description: "",
      startDate: "",
      id: "",
      endDate: "",
      public: "",
      manifestations: [],
      timeSlot: "",
      rates: [],
    }
  }

  static getEmptyManifestation(): Manifestation {
    return {
      gaugeId: "",
      endDate: new Date(),
      startDate: new Date()
    }
  }

  static getEmptyPrice(): Price {
    return {
      display: "",
      amount: -1,
      currency_symbol: ""
    }
  }

  static getEmptyCart(): Cart {
    return {
      id: "",
      totalPrice: EmptyBuilder.getEmptyPrice(),
      items: [],
      payments: []
    }
  }
}

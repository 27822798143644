import {HttpClient} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {environment} from "../../environments/environment"
import {MessageService} from "primeng/api"
import {DataAccessHandler} from "../core/data-access-handler.service"

@Injectable({
  providedIn: "root"
})
export abstract class DataAccessHandlerEve2 extends DataAccessHandler {

  public auth = {
    refreshToken: "",
    accessToken: "",
    accessTokenExpirationTime: new Date(Date.now() + 60 * 60 * 1000),
    accessTokenUrlApi: "oauth/v2/token",
  }

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super(http, messageService)
  }

  getErrorMessage(errorNumber: number = 0): string {
    switch (errorNumber) {
      case 10002: // "Invalid API authentication token
        this.resetAccessToken()
        window.location.reload()
        return ""
      case 30022:
        sessionStorage.removeItem("cart-id")
        return `Panier introuvable, merci de réessayer.`
      case 30006:
        return `Merci de vous identifier.`
      default:
        return `Impossible d'effectuer l'action demandée.`
    }
  }

  public resetAccessToken(): void {
    this.auth.accessToken = ""
    sessionStorage.removeItem("accessToken")
    sessionStorage.removeItem("accessTokenExpirationTime")
    sessionStorage.removeItem("refreshToken")
    sessionStorage.removeItem("cart-id")
  }

  public getAccessToken(refreshing = false): Promise<string> {
    if (sessionStorage.getItem("accessToken") && !refreshing) {
      this.auth.accessTokenExpirationTime = new Date(sessionStorage.getItem("accessTokenExpirationTime") || Date.now())
      this.auth.accessToken = sessionStorage.getItem("accessToken") || ""
      this.auth.refreshToken = sessionStorage.getItem("refreshToken") || ""
      return Promise.resolve(this.auth.accessToken)
    }

    const param = refreshing ? `refresh_token&refresh_token=${this.auth.refreshToken}` : "password"
    const httpResponse = this.http.get<any>(
      this.getUrl(`${this.auth.accessTokenUrlApi}?${environment.api.queryParams}${param}`))

    return this.handlingXhrResponse<{ access_token: string, expires_in: string, refresh_token: string }>(httpResponse)
      .then(response => {
        this.auth.accessTokenExpirationTime = new Date(Date.now() + +response.expires_in * 1000)
        this.auth.accessToken = response.access_token
        this.auth.refreshToken = response.refresh_token

        sessionStorage.setItem("accessTokenExpirationTime", this.auth.accessTokenExpirationTime.toISOString())
        sessionStorage.setItem("accessToken", this.auth.accessToken)
        sessionStorage.setItem("refreshToken", this.auth.refreshToken)
        return this.auth.accessToken
      })
  }

  protected getCurrentCartId(): Promise<string> {
    const cartIdStorage = sessionStorage.getItem("cart-id")
    if (cartIdStorage) {
      return Promise.resolve(cartIdStorage)
    }

    return this.handlingXhrResponse<any>(
      this.http.get<any>(this.getUrl(`v2/carts`)))
      .then(response => {
        const cartId = response._embedded.items[0].id + ""
        sessionStorage.setItem("cart-id", cartId)
        return cartId
      })
  }

}

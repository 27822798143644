import {Injectable} from "@angular/core"

import {HttpClient} from "@angular/common/http"
import {MessageService} from "primeng/api"

import {DataAccessHandlerEve2} from "../../../data-access-handler.eve2.service"
import {AuthService} from "../../../../core/auth.service"
import {ILogout} from "../../../../interfaces/auth/logout/handler.interface"


@Injectable()
export class LogoutHandler extends DataAccessHandlerEve2 implements ILogout {

  constructor(protected http: HttpClient,
              protected messageService: MessageService,
              protected authService: AuthService) {
    super(http, messageService)
  }

  logout(): Promise<boolean> {
    const httpResponse = this.http.post<any>(this.getUrl(`v2/logout`),
      {})

    return this.handlingXhrResponse<any>(httpResponse)
      .then(response => {
        this.authService.clearUserInfo()
        return response
      })
  }

}

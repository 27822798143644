import {Subscription} from "rxjs"
import {
  ComponentFactoryResolver,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from "@angular/core"
import {AbstractControl, NgControl} from "@angular/forms"


const MESSAGES = {
  // the order is important, as only the first error is displayed, if several, we're prioritizing in the following order:
  required: $localize`Ce champ est obligatoire.`,
  dateRange: $localize`L'heure de fin doit être supérieur à l'heure de début.`,
  email: $localize`Adresse mail invalide.`,
  min: $localize`Merci de saisir un nombre positif`,
}
const MESSAGES_PRIO = {} as any
Object.keys(MESSAGES).map((key, index) => MESSAGES_PRIO[key] = index)

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[formControl]:not([no-error]), [formControlName]:not([no-error])"
})

export class ControlErrorsDirective implements OnInit, OnDestroy {

  errorRef: HTMLElement | null = null
  container: ViewContainerRef
  sub: Subscription | null = null

  @Input() firstErrorOnly = true

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private controlDir: NgControl
  ) {
    this.container = this.vcr
  }


  ngOnInit(): void {
    if (this.control) {
      this.sub = this.control.statusChanges
        .subscribe(() => this.showError())
    }
    setTimeout(() => {
      this.updateDOM()
    }, 0) // ensure the DOM is ready before updating it

  }

  updateDOM(): void {

    const ref = this.container.element.nativeElement.parentElement
    // if (ref?.parentElement?.querySelector("*[required],*[ng-reflect-required]")) {
    //   // Automatically adding the asterisk on label when required
    //   ref.parentElement?.querySelector("label")?.classList.add("required")
    // }

    this.errorRef = document.createElement("div")
    this.errorRef.classList.add("error-directive")
    ref.insertAdjacentElement("beforeend", this.errorRef)
    this.errorRef.textContent = "\u00a0"
    // this.errorRef.style.display = "none"
  }

  @HostListener("blur", ["$event"])
  handleBlurEvent(): void {
    // This is needed to handle clicking a required field and moving out
    // Status change subscription managing the remaining cases
    if (!this.control?.value) {
      if (this.control?.errors) {
        this.showError()
      } else {
        this.removeError()
      }
    }
  }

  private showError(): void {
    this.removeError()

    const errors = Object.keys(this.control?.errors || {})
    let firstKey = errors[0]

    if (errors.length > 1) {
      // prioritizing
      errors.sort((a, b) => MESSAGES_PRIO[a] - MESSAGES_PRIO[b])
      firstKey = errors[0]
    }

    if (firstKey && this.errorRef) {
      // @ts-ignore
      this.errorRef.textContent = MESSAGES[firstKey] || firstKey
      this.errorRef.style.display = "block"
    }

  }

  private removeError(): void {
    if (this.errorRef) {
      this.errorRef.textContent = "\u00a0"
    }
  }

  get control(): AbstractControl | null {
    return this.controlDir.control
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe()
  }
}

import {NgModule} from "@angular/core"
import {RouterModule, Routes} from "@angular/router"
import {EventsPageComponent} from "./pages/events-page/events-page.component"
import {EventPageComponent} from "./pages/event-page/event-page.component"
import {CartPageComponent} from "./pages/cart-page/cart-page.component"
import {AccountPageComponent} from "./pages/account-page/account-page.component"

const routes: Routes = [
  {
    path: "",
    component: EventsPageComponent,
  },
  {
    path: "event/:id",
    component: EventPageComponent,
  },
  {
    path: "cart",
    component: CartPageComponent,
  },
  {
    path: "account",
    component: AccountPageComponent,
  },
  {path: "**", pathMatch: "full", redirectTo: "/"}
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component, Inject, OnInit} from "@angular/core"
import {DataAccessHandler} from "../../../data-access/core/data-access-handler.service"
import {IListAllEvents} from "../../../data-access/interfaces/list-all-events/handler.interface"
import {LazyLoadEvent} from "primeng/api"
import {EmptyBuilder} from "../../../data-access/core/emptyBuilder"
import {Event} from "../../../data-access/interfaces/model"


@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"]
})
export class EventsComponent implements OnInit {

  currentPage = 0
  totalRecords = 0
  numberOfEvents = 6
  events: Array<Event> = []

  constructor(private dataAccessHandler: DataAccessHandler,
              @Inject("ListAllEvents") private listAllEventsHandler: IListAllEvents,
  ) {
    this.fetchEvents()

  }

  fetchEvents(event?: LazyLoadEvent): void {
    this.events = new Array<Event>(6)
      .fill(EmptyBuilder.getEmptyEvent())

    const firstElement = event?.first ?? 0
    const elementsPerPage = event?.rows ?? 1
    this.currentPage = firstElement / elementsPerPage + 1

    this.listAllEventsHandler.listAll({
      page: {
        pageNumber: this.currentPage,
        elementsPerPage: this.numberOfEvents
      },
    })
      .then(res => {
        this.events = res.data
        this.totalRecords = res.paging.total_count
        console.log(this.events)
      })
  }

  ngOnInit(): void {
  }

}

import {Injectable} from "@angular/core"
import {HttpClient} from "@angular/common/http"
import {MessageService} from "primeng/api"
import {DataAccessHandlerEve2} from "../../../data-access-handler.eve2.service"
import {ILogin, LoginQuery} from "../../../../interfaces/auth/login/handler.interface"
import {AuthService} from "../../../../core/auth.service"
import {User} from "../../../../interfaces/model"



@Injectable()
export class LoginHandler extends DataAccessHandlerEve2 implements ILogin {

  constructor(protected http: HttpClient,
              protected messageService: MessageService,
              protected authService: AuthService) {
    super(http, messageService)
  }

  login(parameters: LoginQuery): Promise<User> {
    const httpResponse = this.http.post<any>(this.getUrl(`v2/login`),
      parameters)

    return this.handlingXhrResponse<any>(httpResponse, false)
      .then(response => {
        if (response.code === 200) {
          const user = response.success.customer
          this.authService.setUserInfo(user)
          return user
        }
        return Promise.reject("Error login")
      })
  }

}

import {Component, Inject, OnInit} from "@angular/core"
import {ActivatedRoute} from "@angular/router"
import {IGetEvent} from "../../../data-access/interfaces/get-event/handler.interface"
import {Cart, Event, Manifestation, Rate} from "../../../data-access/interfaces/model"
import {EmptyBuilder} from "../../../data-access/core/emptyBuilder"
import {IUpdateCart} from "../../../data-access/interfaces/update-cart/handler.interface"
import {MessageService} from "primeng/api"
import {IGetCart} from "../../../data-access/interfaces/get-cart/handler.interface"

type OptionModel = { value: number, label: string }

@Component({
  selector: "app-event-page",
  templateUrl: "./event-page.component.html",
  styleUrls: ["./event-page.component.scss"]
})
export class EventPageComponent implements OnInit {

  loading = false
  options: Array<OptionModel> = new Array(10).fill(0).map((x, i) => ({value: i, label: i + ""}))
  currentManifestation: Manifestation = EmptyBuilder.getEmptyManifestation()
  event: Event = EmptyBuilder.getEmptyEvent()
  cart: Cart = EmptyBuilder.getEmptyCart()
  openingDates: Array<Date> = []
  date = new Date()

  quantities: { [key: string]: number } = {}

  constructor(private route: ActivatedRoute,
              private messageService: MessageService,
              @Inject("GetEvent") private getEventHandler: IGetEvent,
              @Inject("UpdateCart") private updateCardHandler: IUpdateCart,
              @Inject("GetCart") private getCartHandler: IGetCart,
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get("id") || ""
    if (id) {
      this.loading = true
      this.getEventHandler.getEvent({id})
        .then(event => {
            this.event = event
            this.openingDates = this.event.manifestations.map(m => m.startDate)
            this.setDate(this.openingDates[0] || new Date())
            this.updateCart()
          },
          err => this.updateCart())
    }

  }

  updateCart(requestServer = true): void {
    this.getCartHandler.get()
      .then(cart => {
        this.quantities = {}
        this.event.manifestations.forEach(manif => {
          this.event.rates.forEach(rate => {
            const key = manif.gaugeId + "#" + rate.id
            this.quantities[key] = 0
          })
        })

        cart.items.forEach(item => {
          const key = item.gaugeId + "#" + item.priceId
          this.quantities[key] = item.quantity
        })
        this.loading = false
        this.cart = cart
      })
  }

  setDate(date: Date): void {
    this.date = date
    this.currentManifestation = this.event.manifestations
        .find(x => x.startDate.toLocaleDateString() === this.date.toLocaleDateString())
      || EmptyBuilder.getEmptyManifestation()
  }

  dateChanged(date: Date): void {
    this.setDate(date)
  }

  selectItem(rate: Rate, event: any): void {
    let oldQuantity = 0
    const item1 = this.cart.items.find(item => item.priceId === rate.id && item.gaugeId === this.currentManifestation.gaugeId)
    if (item1) {
      oldQuantity = item1.quantity
    }
    const newQuantity = event.value

    if (newQuantity > oldQuantity) {
      this.loading = true
      this.updateCardHandler.updateQuantities({
        id: rate.id,
        gauge_id: this.currentManifestation.gaugeId,
        quantity: newQuantity - oldQuantity
      })
        .then(x => {
            this.messageService.add({severity: "success", detail: "OK", life: 3000})
            this.updateCart()
          },
          err => this.updateCart())
    } else {
      this.messageService.add({severity: "error", detail: "TODO: la suppression n'est pas gérée", life: 3000})
      this.loading = true
      this.updateCart()
    }
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core"
import {Router} from "@angular/router"

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"]
})
export class CalendarComponent implements OnInit {

  @Input() date: Date | null = null
  @Output() dateUpdated = new EventEmitter()
  minDate = new Date()

  // tslint:disable-next-line:variable-name
  private _openingDates: Array<Date> = []

  @Input() set openingDates(dates: Array<Date>) {
    this._openingDates = dates
    this.updateClosingDates()
  }

  get openingDates(): Array<Date> {
    return this._openingDates
  }


  closingDates: Array<Date> = []
  fullDates: Date[] = []
  disablePreviousMonth = false
  enableNextMonth = true

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    const date = new Date()
    this.changeMonth({month: date.getMonth() + 1, year: date.getFullYear()})
  }

  getDaysInMonth(month: number, year: number): Date[] {
    const date = new Date(year, month - 1, 1)
    const days = []
    while (date.getMonth() === month - 1) {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }
    return days
  }

  changeMonth(event: { month: number, year: number }): void {
    this.checkPreviousMonth(event)
    this.updateClosingDates(event.month, event.year)
  }

  updateClosingDates(month = new Date().getMonth() + 1, year = new Date().getFullYear()): void {
    const allMonthDays = this.getDaysInMonth(month, year)
    this.closingDates = allMonthDays.filter(x =>
      !this.openingDates.find(y => x.toLocaleDateString() === y.toLocaleDateString()))
    this.checkPreviousMonth({month, year})
  }

  dateChanged(date: Date): void {
    this.dateUpdated.emit(date)
  }

  dateIsFull(date: { year: number, month: number, day: number }): boolean {
    const dateStr = new Date(date.year, date.month, date.day).toDateString()
    return !!this.fullDates.find(x => x.toDateString() === dateStr)
  }

  checkPreviousMonth(event: { month: number, year: number }): void {
    const now = new Date()
    this.disablePreviousMonth = now.getFullYear() === event.year && now.getMonth() === (event.month - 1)
    if (this.openingDates.length > 0) {
      this.openingDates.sort((a, b) => a.getTime() - b.getTime())
      const lastEvent = this.openingDates[this.openingDates.length - 1]
      this.enableNextMonth = lastEvent.getFullYear() >= event.year
        && lastEvent.getMonth() >= event.month
    } else {
      this.enableNextMonth = true
    }
  }
}

import {Injectable} from "@angular/core"
import {Parser} from "../parser"
import {DataAccessHandlerEve2} from "../../data-access-handler.eve2.service"
import {IGetCart} from "../../../interfaces/get-cart/handler.interface"
import {Cart} from "../../../interfaces/model"


@Injectable()
export class GetCartHandler extends DataAccessHandlerEve2 implements IGetCart {

  get(): Promise<Cart> {
    return this.getCurrentCartId()
      .then(cartId => {
        const httpResponse = this.http.get<any>(this.getUrl(`v2/carts/${cartId}`))

        return this.handlingXhrResponse<any>(httpResponse)
          .then(response => Parser.toCart(response))
      })
  }

}

import {Injectable} from "@angular/core"
import {CreateUserQuery, ICreateUser} from "../../../../interfaces/auth/create-user/handler.interface"
import {DataAccessHandlerEve2} from "../../../data-access-handler.eve2.service"
import {User} from "../../../../interfaces/model"


@Injectable()
export class CreateUserHandler extends DataAccessHandlerEve2 implements ICreateUser {

  create(parameters: CreateUserQuery): Promise<User> {
    const httpResponse = this.http.post<any>(this.getUrl(`v2/customers`),
      parameters)

    return this.handlingXhrResponse<any>(httpResponse)
      .then(response => {
        return response
      })
  }

}

import {Component, Input, OnInit} from "@angular/core"

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
export class ButtonComponent implements OnInit {

  @Input() label = ""
  @Input() icon = ""
  @Input() loading = false

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {HttpClient} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {environment} from "../../environments/environment"
import {take} from "rxjs/operators"
import {MessageService} from "primeng/api"
import {Observable} from "rxjs"

@Injectable({
  providedIn: "root"
})
export abstract class DataAccessHandler {

  protected static readonly API_URL = environment.api.url

  protected constructor(protected http: HttpClient, protected messageService: MessageService) {
  }

  abstract getErrorMessage(errorNumber: number): string

  protected getUrl(path: string = ""): string {
    if (path !== "") {
      return `${DataAccessHandler.API_URL}/${path}`
    }

    return DataAccessHandler.API_URL
  }

  protected handlingXhrResponse<T>(httpResponse: Observable<T>, showError = true): Promise<T> {
    return new Promise((resolve, reject) => {
      httpResponse.pipe(
        take(1)
      )
        .subscribe(res => resolve(res),
          error => {
            if (showError) {
              this.showErrorMessage(error.message, error?.error?.code)
            }
            reject(error)
          })
    })
  }

  public showErrorMessage(message: string, code?: number): void {
    let errorMessage = message
    if (code) {
      errorMessage = this.getErrorMessage(code)
    }
    this.messageService.add({severity: "error", detail: errorMessage, life: 3000})
  }

}

import {Injectable} from "@angular/core"
import {EventDataRead} from "../eventModel"
import {Parser} from "../parser"
import {DataAccessHandlerEve2} from "../../data-access-handler.eve2.service"
import {IGetEvent} from "../../../interfaces/get-event/handler.interface"
import {IdQuery} from "../../../interfaces/query.interface"
import {Event} from "../../../interfaces/model"


@Injectable()
export class GetEventHandler extends DataAccessHandlerEve2 implements IGetEvent {

  getEvent(parameters: IdQuery): Promise<Event> {
    const httpResponse = this.http.get<any>(this.getUrl(`v2/events/${parameters.id}`))

    return this.handlingXhrResponse<EventDataRead>(httpResponse)
      .then(response => Parser.dataReadEventToEvent(response))
  }

}

<div class="container">
    <div class="logo">
        <img src="assets/logo.svg">
    </div>

    <div class="black-header">Billetterie du Musée des Beaux-arts de Brest</div>
    <p-menubar [model]="authService.isAuthenticated ? itemsConnected : items">
    </p-menubar>
</div>
<div style="height: 120px"></div>

import {LOCALE_ID, NgModule} from "@angular/core"
import {BrowserModule} from "@angular/platform-browser"

import {AppRoutingModule} from "./app-routing.module"
import {AppComponent} from "./app.component"
import {ButtonModule} from "primeng/button"
import {MenubarModule} from "primeng/menubar"
import {HeaderComponent} from "./components/layout/header/header.component"
import {FooterComponent} from "./components/layout/footer/footer.component"
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http"
import {MessageService} from "primeng/api"
import {ToastModule} from "primeng/toast"
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import {ApiAuthInterceptorEve2} from "../data-access/eve2/api.auth.interceptor.eve2"
import {PaginatorModule} from "primeng/paginator"
import {EventComponent} from "./components/event/event.component"
import {EventsComponent} from "./components/events/events.component"
import {ProgressSpinnerModule} from "primeng/progressspinner"
import {EventsPageComponent} from "./pages/events-page/events-page.component"
import {EventPageComponent} from "./pages/event-page/event-page.component"
import {CalendarComponent} from "./components/calendar/calendar.component"
import {CalendarModule} from "primeng/calendar"
import {DialogService} from "primeng/dynamicdialog"
import {CartPageComponent} from "./pages/cart-page/cart-page.component"
import {CreateAccountFormComponent} from "./components/auth/create-account-form/create-account-form.component"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {InputTextModule} from "primeng/inputtext"
import {ControlErrorsDirective} from "./directives/control-errors.directive"
import {AccountPageComponent} from "./pages/account-page/account-page.component"
import {UpdateCartHandler} from "../data-access/eve2/handlers/update-cart/handler"
import {ListAllEventsHandler} from "../data-access/eve2/handlers/list-all-events/handler"
import {GetEventHandler} from "../data-access/eve2/handlers/get-event/handler"
import {CreateOrderHandler} from "../data-access/eve2/handlers/create-order/handler"
import {CreatePaymentHandler} from "../data-access/eve2/handlers/create-payment/handler"
import {GetCartHandler} from "../data-access/eve2/handlers/get-cart/handler"
import {LoginHandler} from "../data-access/eve2/handlers/auth/login/handler"
import {LogoutHandler} from "../data-access/eve2/handlers/auth/logout/handler"
import {CreateUserHandler} from "../data-access/eve2/handlers/auth/create-user/handler"
import { ButtonComponent } from "./components/button/button.component"


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    EventComponent,
    EventsComponent,
    EventsPageComponent,
    EventPageComponent,
    CalendarComponent,
    CartPageComponent,
    CreateAccountFormComponent,
    ControlErrorsDirective,
    AccountPageComponent,
    ButtonComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    ButtonModule,
    MenubarModule,
    ToastModule,
    PaginatorModule,
    ProgressSpinnerModule,
    CalendarModule,
    InputTextModule,

    ReactiveFormsModule,
    FormsModule

  ],
  providers: [
    MessageService,
    DialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptorEve2,
      multi: true
    },
    {provide: LOCALE_ID, useValue: "fr-FR"},


    {provide: "UpdateCart", useClass: UpdateCartHandler},
    {provide: "ListAllEvents", useClass: ListAllEventsHandler},
    {provide: "GetEvent", useClass: GetEventHandler},
    {provide: "CreateOrder", useClass: CreateOrderHandler},
    {provide: "CreatePayment", useClass: CreatePaymentHandler},
    {provide: "GetCart", useClass: GetCartHandler},

    {provide: "Login", useClass: LoginHandler},
    {provide: "Logout", useClass: LogoutHandler},
    {provide: "CreateUser", useClass: CreateUserHandler},

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

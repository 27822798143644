import {Component, Inject, OnInit} from "@angular/core"
import {IGetCart} from "../../../data-access/interfaces/get-cart/handler.interface"
import {ICreatePayment} from "../../../data-access/interfaces/create-payment/handler.interface"
import {Cart} from "../../../data-access/interfaces/model"
import {EmptyBuilder} from "../../../data-access/core/emptyBuilder"
import {AuthService} from "../../../data-access/core/auth.service"
import {HelperService} from "../../services/helper.service"


@Component({
  selector: "app-cart-page",
  templateUrl: "./cart-page.component.html",
  styleUrls: ["./cart-page.component.scss"]
})
export class CartPageComponent implements OnInit {

  loading = true
  loadingPayment = false
  html = ""
  cart: Cart = EmptyBuilder.getEmptyCart()

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    @Inject("GetCart") private getCartHandler: IGetCart,
    @Inject("CreatePayment") private createPaymentHandler: ICreatePayment,
  ) {
  }

  ngOnInit(): void {
    this.getCartHandler.get()
      .then(cart => {
        this.cart = cart
        this.loading = false
      })
  }

  makePayment(): void {
    if (this.authService.isAuthenticated) {
      this.loadingPayment = true
      this.createPaymentHandler.create()
        .then(x => {
            this.html = x.methods.PayboxPayment.html
            const dom = document.getElementById("test")
            if (dom) {
              dom.innerHTML = this.html
            }
            this.loadingPayment = false
          },
          () => this.loadingPayment = false)
    } else {
      this.helperService.showLoginPopup()
        .then(connected => {
          if (connected) {
            this.makePayment()
          }
        })
    }
  }
}

import {Injectable} from "@angular/core"
import {ICreateOrder, OrderQuery} from "../../../interfaces/create-order/handler.interface"
import {DataAccessHandlerEve2} from "../../data-access-handler.eve2.service"


@Injectable()
export class CreateOrderHandler extends DataAccessHandlerEve2 implements ICreateOrder {

  create(parameters?: OrderQuery): Promise<any> {

    return this.getCurrentCartId()
      .then(cartId => {
        const httpResponse = this.http.get<any>(this.getUrl(`v2/checkouts/${cartId}`))
        return this.handlingXhrResponse<any>(httpResponse)
          .then(response => (response))
      })
  }

}

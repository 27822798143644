import {Injectable} from "@angular/core"
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http"
import {from, Observable, of} from "rxjs"

import {mergeMap} from "rxjs/operators"
import {DataAccessHandlerEve2} from "./data-access-handler.eve2.service"

@Injectable({
  providedIn: "root"
})
export class ApiAuthInterceptorEve2 implements HttpInterceptor {
  constructor(private dataAccessHandler: DataAccessHandlerEve2) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (req.url.includes(this.dataAccessHandler.auth.accessTokenUrlApi)) {
      return next.handle(req)
    }

    const refreshing = this.dataAccessHandler.auth.accessTokenExpirationTime.getTime() <= Date.now()

    return (
      this.dataAccessHandler.auth.accessToken && !refreshing ?
        of(this.dataAccessHandler.auth.accessToken) :
        from(this.dataAccessHandler.getAccessToken(refreshing))
    )
      .pipe(
        mergeMap(token => {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            }
          })
          return next.handle(req)
        }))
  }
}

import {Cart, CartItem, Event, Manifestation, Rate} from "../../interfaces/model"
import {EventDataRead, ManifestationDataRead, RateDataRead} from "./eventModel"

import {CartDataRead} from "./cartModel"
import {PagingResponse} from "../../core/data-response.model"


export class Parser {
  static toShortDateStr(date: Date): string {
    return date.toLocaleDateString("FR-fr", {weekday: "long", year: "numeric", month: "long", day: "numeric"})
  }

  static toDate(str: string): Date {
    return new Date(str.split("")
      .map((x, i) => x + ([3, 5].includes(i) ? "-" : "") +
        ([10, 12].includes(i) ? ":" : "")).join(""))
  }


  static toCart(cartDataRead: CartDataRead): Cart {
    return {
      id: cartDataRead.id + "",
      totalPrice: {
        amount: cartDataRead.total,
        currency_symbol: "€",
        display: cartDataRead.total + " €",
      },
      items: Parser.toCartItems(cartDataRead.items),
      payments: []
    }
  }

  static toCartItems(data: any): Array<CartItem> {
    const items = data?.map((x: any) => ({
      id: x.id + "",
      quantity: x.quantity,
      description: x.price.translations.fr.description,
      name: x.price.translations.fr.name,
      priceId: x.price.id + "",
      gaugeId: x.declination.id + "",
      unitPrice: {
        display: x.unitPrice + " €",
        currency_symbol: "€",
        amount: x.unitPrice,
      },
      totalPrice: {
        display: x.unitsTotal + " €",
        currency_symbol: "€",
        amount: x.unitsTotal,
      },
    })) || []

    return items.reduce((acc: Array<CartItem>, value: CartItem) => {
      const existingItem = acc.find(x => x.gaugeId === value.gaugeId && x.priceId === value.priceId)
      if (existingItem) {
        existingItem.quantity += value.quantity
      } else {
        acc.push(value)
      }
      return acc
    }, [] as Array<CartItem>)
  }

  static toPaging(data: any): PagingResponse {
    return {
      page: data.page,
      count: data.pages,
      limit: data.limit,
      total_count: data.total,
    }
  }

  // static toOrder(data: OrderDataRead): Order {
  //   return data as any
  // }

  static toRate(data: RateDataRead): Rate {
    return {
      id: `${data.id}`,
      name: data.translations.fr.name,
      value: {
        amount: +data.value,
        display: data.value + " €",
        currency_symbol: "€",
      }
    }
  }

  static toManifestation(data: ManifestationDataRead): Manifestation {
    return {
      endDate: Parser.toDate(data.endsAt),
      startDate: Parser.toDate(data.startsAt),
      gaugeId: data.gauges[0].id + ""
    }
  }

  static dataReadEventToEvent(evt: EventDataRead): Event {
    const manifestations = evt.manifestations.map(x => Parser.toManifestation(x))
      .sort((a, b) => a.endDate.getTime() - b.endDate.getTime())

    let timeSlot = manifestations.length > 0 ? `Le ${Parser.toShortDateStr(manifestations[0].startDate)}` : ""
    if (manifestations.length > 1) {
      timeSlot =
        `Du ${Parser.toShortDateStr(manifestations[0].startDate)} au ` +
        Parser.toShortDateStr(manifestations[manifestations.length - 1].startDate)
    }


    return {
      id: `${evt.id}`,
      public: evt.metaEvent?.translations?.fr?.name,
      category: evt.category,
      description: evt.translations.fr.description || "Ceci est une description. ".repeat(10),
      imageUrl: "/assets/tmp.jpg", // ev.imageURL,
      title: evt.translations.fr.name,
      timeSlot,
      startDate: "",
      endDate: "",
      manifestations,
      rates: evt.manifestations[0]?.gauges[0]?.prices.map(x => Parser.toRate(x)),
    }
  }
}

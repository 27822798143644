import {Injectable} from "@angular/core"
import {FormGroup} from "@angular/forms"
import {CreateAccountFormComponent} from "../components/auth/create-account-form/create-account-form.component"
import {DialogService} from "primeng/dynamicdialog"
import {take} from "rxjs/operators"

export function showFormErrors(fg: FormGroup): void {
  fg.markAsDirty()
  fg.updateValueAndValidity()
  const fg1 = fg as any
  fg1._forEachChild((control: any) => {
    if (control instanceof FormGroup) {
      showFormErrors(control)
    } else {
      control.markAsDirty()
      control.updateValueAndValidity()
    }
  })
}


@Injectable({
  providedIn: "root"
})
export class HelperService {

  constructor(private dialogService: DialogService) {
  }

  showLoginPopup(): Promise<boolean> {
    const ref = this.dialogService.open(CreateAccountFormComponent, {
      header: "Se connecter",
      width: "400px"
    })

    return new Promise(resolve => {
      ref.onClose.pipe(take(1)).subscribe(connected => resolve(connected))
    })
  }
}

import {Component, Input, OnInit} from "@angular/core"
import {EmptyBuilder} from "../../../data-access/core/emptyBuilder"
import {Event} from "../../../data-access/interfaces/model"


@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"]
})
export class EventComponent implements OnInit {


  @Input() event: Event = EmptyBuilder.getEmptyEvent()

  constructor() {
  }

  ngOnInit(): void {
  }

}

export interface ListSortParameters {
  fieldToSort: string
  sortOrder: boolean
}

export interface ListPageParameters {
  pageNumber: number
  elementsPerPage: number
}

export interface ListQuery {
  sort?: ListSortParameters
  page?: ListPageParameters
  search?: string
}

export interface IdQuery {
  id: string
}

export function getListQueryUrl(url: string, parameters?: ListQuery): string {

  const urlObj = new URL(url)

  if (parameters?.page) {
    urlObj.searchParams.append("page", `${parameters.page.pageNumber}`)
    urlObj.searchParams.append("limit", `${parameters.page.elementsPerPage}`)
  }
  if (parameters?.search) {
    urlObj.searchParams.append("search", parameters.search)
  }
  // TODO sorting

  return urlObj.toString()
}

<div class="container">
    <div [ngClass]="{'description' : true, 'loading': !event.id}">
        <div class="image"
             [style.background-image]="'url(' + event.imageUrl + ')'"></div>

        <div class="text">
            <div class="public">{{event.public}}</div>
            <div class="title">{{event.title}}</div>
            <div class="date">{{event.timeSlot}}</div>
            <div class="description">{{event.description}}</div>
        </div>
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="separator"></div>
    <div [ngClass]="{'loading': loading}">
        <div style="display: flex">
            <app-calendar [openingDates]="openingDates" (dateUpdated)="dateChanged($event)"
                          [date]="date"></app-calendar>

            <div class="prices">
                <div *ngFor="let rate of event.rates" class="price">
                    <div>{{rate.name}} - {{rate.value.display}}</div>
                    <p-dropdown [options]="options"
                                [(ngModel)]="quantities[currentManifestation.gaugeId + '#' + rate.id]"
                                appendTo="body" (onChange)="selectItem(rate, $event)"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="btn">
            <p-button routerLink="/cart">Valider le panier</p-button>
        </div>
    </div>

</div>

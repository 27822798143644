import {Injectable} from "@angular/core"
import {DataAccessHandlerEve2} from "../../data-access-handler.eve2.service"
import {ICreatePayment} from "../../../interfaces/create-payment/handler.interface"


@Injectable()
export class CreatePaymentHandler extends DataAccessHandlerEve2 implements ICreatePayment {

  create(): Promise<any> {
    return this.getCurrentCartId()
      .then(cartId => {
        const httpResponse = this.http.get<any>(this.getUrl(`v2/checkouts/select-payment/${cartId}`))
        return this.handlingXhrResponse<any>(httpResponse)
          .then(response => {
            const paymentId = response?.methods[0]?.id || ""
            const httpResponse2 = this.http.post<any>(this.getUrl(`v2/checkouts/select-payment/${cartId}`),
              {method_id: paymentId})
            return this.handlingXhrResponse<any>(httpResponse2)
          })
      })
  }

}

<div class="content">
    <form [formGroup]="fg" class="field" *ngIf="toggle; else loginFormBlock;">
        <div class="field">
            <input pInputText formControlName="lastName" placeholder="Nom" required>
        </div>
        <div class="field">
            <input pInputText formControlName="firstName" placeholder="Prénom" required>
        </div>
        <div class="field">
            <input pInputText formControlName="email" placeholder="Email" required>
        </div>
        <div class="field">
            <input pInputText formControlName="password" placeholder="Mot de passe" required>
        </div>
    </form>
    <ng-template #loginFormBlock>
        <p>Saisissez vos identifiants</p>
        <form [formGroup]="fg2" class="p-fluid">
            <div class="field">
                <input pInputText formControlName="email" placeholder="Email" required
                       autocomplete="username">
            </div>
            <div class="field">
                <input pInputText formControlName="password" placeholder="Mot de passe" required
                       autocomplete="current-password">
            </div>
        </form>
        <!--        <div (click)="todo()" class="link">Mot de passe oublié</div>-->
        <div class="errors">{{errors}}</div>
    </ng-template>

</div>
<div class="ui-dialog-footer">
    <app-button label="Créer un compte" (click)="!loading && createAccount()" [loading]="loading"
                [style.opacity]="fg?.invalid ? 0.5: 1"
                *ngIf="toggle; else loginBlock;">
    </app-button>
    <ng-template #loginBlock>
        <app-button label="Se connecter" (click)="!loading && tryLogin()" [loading]="loading"
                    [style.opacity]="fg2?.invalid ? 0.5: 1">
        </app-button>
    </ng-template>
    <div>
        <p-button (click)="cancel()" styleClass="p-button-outlined">Annuler</p-button>
    </div>

</div>
<div class="link" (click)="toggleCreateLogin()">{{toggle ? "Se connecter" : "Créer un compte"}}</div>


<div class="container" [routerLink]="'/event/' + event.id" *ngIf="event.id; else elseblock;">
    <div class="image"
         [style.background-image]="'url(' + event.imageUrl + ')'"></div>

    <div class="text">
        <div class="date">{{event.timeSlot}}</div>
        <div class="title">{{event.title}}</div>
        <div class="description">{{event.description}}</div>
    </div>
</div>
<ng-template #elseblock>
    <div class="container spinner">
        <p-progressSpinner></p-progressSpinner>
    </div>

</ng-template>

<ng-container *ngIf="!loading; else loadingBlock;">
    <ng-container *ngIf="cart.items.length > 0; else emptyCartBlock">
        <h2>Votre panier</h2>
        <div><i>TODO: recupérer le detail du panier (api eve2 non friendly => 2 requêtes par ligne necessaires...)</i>
        </div>

        <div *ngFor="let item of cart.items">
            {{item.quantity}} {{item.description}} {{item.priceId}} {{item.unitPrice.display}}
        </div>
        <div>TOTAL: {{cart.totalPrice.display}}</div>

        <br>
        <app-button label="Paiement" (click)="!loadingPayment && makePayment()" [loading]="loadingPayment"></app-button>
        <div id="test"></div>
    </ng-container>
    <ng-template #emptyCartBlock>
        <div>Votre panier est vide</div>
    </ng-template>
</ng-container>
<ng-template #loadingBlock>
    <p-progressSpinner></p-progressSpinner>
</ng-template>
